import { ENVIRONMENT_QUAL } from './qual';

export const environment = {
  ...ENVIRONMENT_QUAL,
  apiServer: 'https://washington.app.1life.com',
  myoneServer: 'https://washington.app.1life.com',
  sentry: {
    enabled: true,
    dsn: 'https://94fb2cb964527591835f763ad4493a4f@o4506221115408384.ingest.us.sentry.io/4506855554154496',
    environment: 'onelife-washington',
  },
  stripePublishableKey: 'pk_test_1BgdJYIyf82JQC58S4yInVou',
  launchDarklyClientId: '605034c0e13fe80c650be9bc',
};
